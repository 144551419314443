import {Injectable} from '@angular/core';
import {map, share, tap} from 'rxjs/operators';
import {Article} from '../models/article/article';
import {DataService} from './data.service';
import {Allergen} from '../models/allergen';
import {UnitDefinition} from '../models/unit-definition';
import {LoadingService} from './loading.service';
import {ToastService} from './toast.service';
import {SellUnitDefinition} from '../models/sell-unit-definition';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  public limit = 50;
  public offset = 0;
  public orderProperty: string;
  public orderDirection: string;
  public noMoreArticles = false;
  public unpublished = true;
  public search = '';
  public category = null;
  public articles: Article[] = [];
  public featuredArticles: Article[];
  public allergens: Allergen[] = [];
  public units: UnitDefinition[] = [];
  public sellUnits: SellUnitDefinition[] = [];

  constructor(
    private loadingService: LoadingService,
    private dataService: DataService,
    private toastService: ToastService
  ) {
  }

  async getLoadedArticle(id: number): Promise<Article> {
    if (this.articles.length === 0) {
      await this.getArticles(true);
    }
    let selectedArticle;
    this.articles.forEach((article) => {
      if (article.id === id) {
        selectedArticle = article;
      }
    });
    return selectedArticle;
  }

  async getArticle(id: number): Promise<Article> {
    await this.loadingService.present();

    return this.dataService.get<Article>('/article/' + id, true)
      .pipe(map(response => {
        this.loadingService.dismiss();
        return new Article().deserialize(response) as Article;
      }))
      .toPromise();
  }

  public buildParams() {
    return {
      ...(this.limit ? {limit: this.limit} : {}),
      ...(this.offset ? {offset: this.offset} : {}),
      ...(this.orderProperty ? {orderProperty: this.orderProperty} : {}),
      ...(this.orderDirection ? {orderDirection: this.orderDirection} : {}),
      ...(this.search ? {search: this.search} : {}),
      ...(this.category ? {category: this.category} : {}),
      ...(this.unpublished ? {unpublished: this.unpublished} : {}),
    };
  }

  public async getArticles(force = false, limit = true): Promise<Article[] | undefined> {
    this.offset = 0;
    await this.loadingService.present();

    const params = this.buildParams();
    if (!limit) {
      delete params.limit;
    }

    if (this.articles?.length === 0 || force) {
      this.articles = undefined;
      return this.dataService.getMultiple('/article', Article, true, true, params)
        .pipe(tap(data => {
          this.articles = data;
          this.noMoreArticles = this.articles.length < this.limit;

          this.loadingService.dismiss();
          return this.articles;
        }))
        .pipe(share())
        .toPromise()
        .catch(_ => {
          this.loadingService.dismiss();
          this.toastService.presentToast('Laden fehlgeschlagen', 'danger');
          return null;
        });
    } else {
      await this.loadingService.dismiss();
      return this.articles;
    }
  }

  public async getFeaturedArticles(force = false): Promise<Article[] | undefined> {
    await this.loadingService.present();

    if (this.featuredArticles?.length === 0 || force) {
      this.featuredArticles = undefined;
      return this.dataService.getMultiple('/article/featured', Article, true, true)
        .pipe(tap(data => {
          this.featuredArticles = data;

          this.loadingService.dismiss();
          return this.featuredArticles;
        }))
        .pipe(share())
        .toPromise()
        .catch(_ => {
          this.loadingService.dismiss();
          this.toastService.presentToast('Laden fehlgeschlagen', 'danger');
          return null;
        });
    } else {
      await this.loadingService.dismiss();
      return this.featuredArticles;
    }
  }

  async loadMore(event) {
    this.offset = this.articles.length;

    const newArticles = await this.appendArticles(true);
    this.articles = this.articles.concat(newArticles);
    event.target.complete();
  }

  public async appendArticles(force = false): Promise<Article[] | undefined> {
    if (this.articles.length === 0 || force) {
      return this.dataService.getMultiple('/article', Article, true, true, this.buildParams())
        .pipe(tap(data => {
          if (data.length < this.limit) {
            this.noMoreArticles = true;
          }
          return data;
        }))
        .pipe(share())
        .toPromise()
        .catch(_ => {
          this.toastService.presentToast('Laden fehlgeschlagen', 'danger');
          return null;
        });
    } else {
      return this.articles;
    }
  }

  public async getAllergens(force = false): Promise<Allergen[] | undefined> {
    if (this.allergens?.length === 0 || force) {
      this.allergens = undefined;
      return this.dataService.getMultiple('/allergen', Allergen, true, true)
        .pipe(tap(data => {
          this.allergens = data;

          return this.allergens;
        }))
        .pipe(share())
        .toPromise().catch(_ => {
          this.toastService.presentToast('Laden fehlgeschlagen', 'danger');
          return null;
        });
    } else {
      return this.allergens;
    }
  }

  async postArticle(article: Article): Promise<boolean> {
    await this.loadingService.present();

    return this.dataService.post('/article', article.serialize(), null, true)
      .pipe(
        map(() => {
          this.loadingService.dismiss();
          this.toastService.presentToast('Speichern erfolgreich', 'success');
          return true;
        }),
        share())
      .toPromise()
      .catch(_ => {
        this.loadingService.dismiss();
        this.toastService.presentToast('Speichern fehlgeschlagen', 'danger');
        return null;
      });
  }

  async deleteArticle(id): Promise<boolean> {
    await this.loadingService.present();

    return this.dataService.delete('/article/' + id, true, true)
      .pipe(
        map(() => {
          this.loadingService.dismiss();
          this.toastService.presentToast('Löschen erfolgreich', 'success');
          return true;
        }),
        share())
      .toPromise()
      .catch(_ => {
        this.loadingService.dismiss();
        this.toastService.presentToast('Löschen fehlgeschlagen', 'danger');
        return null;
      });
  }

  async updateArticle(article: Article) {
    await this.loadingService.present();

    return this.dataService.put('/article/' + article.id, true, true, article.serialize())
      .pipe(
        map(() => {
          this.loadingService.dismiss();
          this.toastService.presentToast('Aktualisieren erfolgreich', 'success');
          return true;
        }),
        share())
      .toPromise()
      .catch(_ => {
        this.loadingService.dismiss();
        this.toastService.presentToast('Aktualisieren fehlgeschlagen', 'danger');
        return null;
      });
  }

  async getUnits(force = false) {
    if (this.units?.length === 0 || force) {
      this.units = undefined;
      return this.dataService.getMultiple('/unit', UnitDefinition, true, true)
        .pipe(tap(data => {
          this.units = data;

          return this.units;
        }))
        .pipe(share())
        .toPromise()
        .catch(_ => {
          this.loadingService.dismiss();
          this.toastService.presentToast('Laden fehlgeschlagen', 'danger');
          return null;
        });
    } else {
      return this.units;
    }
  }

  async getSellUnits(force = false) {
    if (this.sellUnits?.length === 0 || force) {
      this.sellUnits = undefined;
      return this.dataService.getMultiple('/sell-unit', SellUnitDefinition, true, true)
        .pipe(tap(data => {
          this.sellUnits = data;

          return this.sellUnits;
        }))
        .pipe(share())
        .toPromise()
        .catch(_ => {
          this.loadingService.dismiss();
          this.toastService.presentToast('Laden fehlgeschlagen', 'danger');
          return null;
        });
    } else {
      return this.sellUnits;
    }
  }

  async saveFeaturedArticles(ids: number[]) {
    await this.loadingService.present();

    return this.dataService.put('/article/featured', true, true, ids)
      .pipe(
        map(() => {
          this.loadingService.dismiss();
          this.toastService.presentToast('Aktualisieren erfolgreich', 'success');
          return true;
        }),
        share())
      .toPromise()
      .catch(_ => {
        this.loadingService.dismiss();
        this.toastService.presentToast('Aktualisieren fehlgeschlagen', 'danger');
        return null;
      });
  }

  async updateArticlePrices(newPrices: any[]) {
    await this.loadingService.present();

    return this.dataService.put('/article/prices', true, true, newPrices)
      .pipe(
        map(data => {
          this.loadingService.dismiss();

          if (data['failed']?.length > 0) {
            this.toastService.presentButtonToast(
              'Aktualisieren erfolgreich',
              'success',
              'Folgende Ids wurden jedoch nicht gefunden: ' + data['failed'].toString(),
              'Verstanden'
            );
          } else {
            this.toastService.presentToast('Aktualisieren erfolgreich', 'success');
          }

          return true;
        }),
        share())
      .toPromise()
      .catch(_ => {
        this.loadingService.dismiss();
        this.toastService.presentToast('Aktualisieren fehlgeschlagen', 'danger');
        return null;
      });
  }
}
