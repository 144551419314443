import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Serializable} from '../models/serializable.abstract';
import {map} from 'rxjs/operators';
import {Capacitor} from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public apiUrl = environment.apiUrl;

  constructor(
    private httpClient: HttpClient
  ) {
    const currentUrl = window.location.href;

    if (currentUrl.includes('pilot')) {
      this.apiUrl = environment.apiUrlPilot;
    } else if (currentUrl.includes('admin.orderfant.app') || Capacitor.isNativePlatform()) {
      this.apiUrl = environment.apiUrlProd;
    }
  }

  public get<T>(route: string, withCredentials = false, loading = true, params = {}): Observable<T> {
    return this.httpClient.get<T>(this.apiUrl + route, {
      params,
      withCredentials,
      headers: loading ? {} : {'X-Skip-Loading-Interceptor': ''}
    });
  }

  public getText(route: string, withCredentials = false, loading = true, params = {}): Observable<string> {
    return this.httpClient.get(this.apiUrl + route, {
      responseType: 'text',
      params,
      withCredentials,
      headers: loading ? {} : {'X-Skip-Loading-Interceptor': ''}
    });
  }

  public getMultiple<DTO extends Serializable, T extends DTO>(
    route: string,
    type: new() => T,
    withCredentials = false,
    loading = true,
    params = {}
  ): Observable<T[]> {
    return this.get<DTO[]>(route, withCredentials, loading, params)
      .pipe(
        map(data => {
          const items: T[] = [];
          data.forEach(item => {
            items.push(new type().deserialize(item) as T);
          });
          return items;
        })
      );
  }

  public post<T>(route: string, body: any | null, params = null, withCredentials = true): Observable<T> {
    return this.httpClient.post<T>(this.apiUrl + route, body, {
      params,
      withCredentials
    });
  }

  public put<T>(route: string, withCredentials = false, loading = true, body: any | null, params: HttpParams = null): Observable<T> {
    return this.httpClient.put<T>(this.apiUrl + route, body, {
      params,
      withCredentials,
      headers: loading ? {} : {'X-Skip-Loading-Interceptor': ''}
    });
  }

  public delete<T>(route: string, withCredentials = false, loading = true, params = {}): Observable<T> {
    return this.httpClient.delete<T>(this.apiUrl + route, {
      params,
      withCredentials,
      headers: loading ? {} : {'X-Skip-Loading-Interceptor': ''}
    });
  }

  public downloadFile(route: string, fileName: string): void {
    const link = document.createElement('a');
    link.href = this.apiUrl + route;
    link.download = fileName;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
