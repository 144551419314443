import {BaseArticle} from './base-article';
import {Serializable} from '../serializable.abstract';
import {MenuArticle} from '../menu-article';
import {ArticleAttribute} from '../article-attribute';

export class OrderArticleDTO extends BaseArticle {
  tstamp: string;
  net: number;
  gross: number;
  tax: number;
  count: number;
  comment: string;
  additionalArticles: MenuArticle[] = [];
  articleAttributes: ArticleAttribute[] = [];

  public deserialize(input: any): Serializable {
    super.deserialize(input);
    this.deserializeComplexArray('additionalArticles', input, MenuArticle);
    this.deserializeComplexArray('articleAttributes', input, ArticleAttribute);
    return this;
  }

  public serialize(): any {
    return {
      id: this.id,
      tstamp: this.tstamp,
      title: this.title,
      count: this.count,
      amount: this.count,
      unit: this.unit.id,
      sellUnit: this.sellUnit.id,
      comment: this.comment,
      type: 0
    };
  }
}

export class OrderArticle extends OrderArticleDTO {
}
