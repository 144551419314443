import {Serializable} from './serializable.abstract';
import {Branch} from './branch';
import {Table} from './table';

export class MeDTO extends Serializable {
  username: string;
  branches: Array<Branch> = [];
  roles: Array<string> = [];
  table: Table;


  public override deserialize(input: any): Serializable {
    super.deserialize(input);
    this.deserializeComplexArray('branches', input, Branch);
    return this;
  }
}

export class User extends MeDTO{
}
