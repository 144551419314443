import {Injectable} from '@angular/core';
import {BluetoothSerial} from '@ionic-native/bluetooth-serial/ngx';
import {OrderService} from './order.service';
import {LoadingService} from './loading.service';
import {ToastService} from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  public availableDevices: any = [];
  public pairedDevices: any = [];
  public selectedDevice: any;
  public printingIsEnabled: boolean;
  public bluetoothIsEnabled: boolean;

  constructor(
    public btSerial: BluetoothSerial,
    public orderService: OrderService,
    private loadingService: LoadingService,
    private toastService: ToastService
  ) {
  }

  selectDevice(device, printing?) {
    this.selectedDevice = device;
    this.printingIsEnabled = printing ?? true;
    localStorage.setItem('printer', JSON.stringify(this.selectedDevice));
    localStorage.setItem('printing', JSON.stringify(this.printingIsEnabled));
  }

  unselectDevice() {
    this.selectedDevice = null;
    this.printingIsEnabled = false;
    localStorage.removeItem('printer');
    localStorage.removeItem('printing');
  }

  listPairedDevices() {
    return this.btSerial.list();
  }

  connectToBluetoothPrinter(macAddress) {
    return this.btSerial.connect(macAddress);
  }

  disconnectBluetoothPrinter() {
    return this.btSerial.disconnect();
  }

  async printOrder(order) {
    try {
      await this.loadingService.present();

      let orderString = await this.orderService.getOrderBon(order.id);

      orderString = orderString.replace(/\u00c4/g, '\x8E') // Ä
        .replace(/\u00e4/g, '\x84') // ä
        .replace(/\u00d6/g, '\x99') // Ö
        .replace(/\u00f6/g, '\x94') // ö
        .replace(/\u00dc/g, '\x9A') // Ü
        .replace(/\u00fc/g, '\x81') // ü
        .replace(/\u00df/g, '\xE1'); // ß

      this.sendToBluetoothPrinter(orderString);

      await this.loadingService.dismiss();
    } catch (e) {
      await this.loadingService.dismiss();
      await this.toastService.presentToast('Fehler beim Drucken!', 'danger');
    }
  }

  sendToBluetoothPrinter(dataString) {
    this.connectToBluetoothPrinter(this.selectedDevice.address)
      .subscribe(data => {
        this.btSerial.write(dataString)
          .then(async () => {
            await this.disconnectBluetoothPrinter();
          }, err => {
            console.log('Fehler beim Drucken!', err);
          });
      }, async err => {
        await this.toastService.presentToast('Verbindung zum Drucker konnte nicht hergestellt werden!', 'danger');
      });
  }

  togglePrinting() {
    localStorage.setItem('printing', JSON.stringify(this.printingIsEnabled));
  }

  checkBluetoothEnabled() {
    return this.btSerial.isEnabled();
  }

  enableBluetooth() {
    return this.btSerial.enable();
  }

  openSettings() {
    return this.btSerial.showBluetoothSettings();
  }
}
