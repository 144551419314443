import {Serializable} from './serializable.abstract';
import {Article} from './article/article';

export class MenuArticleDTO extends Serializable {
  title = '';
  additionalPrice = 0;
  article: Article = null;
  menuCategory: number = null;

  deserialize(input: any): Serializable {
    super.deserialize(input);
    this.deserializeComplexType('article', input, Article);
    // this.deserializeComplexType('category', input, MenuCategory);
    return this;
  }

  serialize(): any {
    return {
      id: this.id,
      title: this.title,
      additionalPrice: this.additionalPrice,
      article: this.article?.id,
      menuCategory: this.menuCategory
    };
  }
}

export class MenuArticle extends MenuArticleDTO {
  checked = false;
}
