import {Serializable} from '../serializable.abstract';
import {Category} from '../category';
import {Allergen} from '../allergen';
import {MenuCategory} from '../menu-category';
import {BaseArticle} from './base-article';
import {Image} from '../image';
import {ArticleAttribute} from '../article-attribute';

export class ArticleDTO extends BaseArticle {
  description: string;
  hidden: boolean;
  images: Image[] = [];
  price: number;
  thumbnail: number;
  minOrderAmount: number;
  maxOrderAmount: number;
  clickAmount: number;
  taxRate: number;
  topArticle: boolean;
  featuredArticle: boolean;
  hoursLeadTime: number;
  specialPrice: number;
  specialPriceValidFrom: string;
  specialPriceValidUntil: string;
  offerValidFrom: string;
  offerValidUntil: string;
  categories: Category[] = [];
  internalCategories: Category[] = [];
  menuCategories: MenuCategory[] = [];
  articleAttributes: ArticleAttribute[] = [];
  allergens: Allergen[] = [];
  availableDays: number[] = [];
  productNumber: string;

  public override deserialize(input: any): Serializable {
    super.deserialize(input);
    this.deserializeComplexArray('images', input, Image);
    this.deserializeComplexArray('categories', input, Category);
    this.deserializeComplexArray('internalCategories', input, Category);
    this.deserializeComplexArray('allergens', input, Allergen);
    this.deserializeComplexArray('menuCategories', input, MenuCategory);
    this.deserializeComplexArray('articleAttributes', input, ArticleAttribute);
    return this;
  }

  serialize(): any {
    return {
      id: this.id,
      internalId: this.internalId,
      title: this.title,
      unit: this.unit.id,
      sellUnit: this.sellUnit?.id,
      description: this.description,
      hidden: this.hidden,
      price: this.price,
      thumbnail: this.thumbnail,
      minOrderAmount: this.minOrderAmount,
      maxOrderAmount: this.maxOrderAmount,
      clickAmount: this.clickAmount,
      taxRate: this.taxRate,
      topArticle: this.topArticle,
      featuredArticle: this.featuredArticle ?? false,
      hoursLeadTime: this.hoursLeadTime,
      specialPrice: this.specialPrice,
      specialPriceValidFrom: this.specialPriceValidFrom,
      specialPriceValidUntil: this.specialPriceValidUntil,
      offerValidFrom: this.offerValidFrom,
      offerValidUntil: this.offerValidUntil,
      images: this.images,
      categories: this.categories.map(c => c.id),
      internalCategories: this.internalCategories.map(c => c.id),
      menuCategories: this.menuCategories.map(c => c.id),
      articleAttributes: this.articleAttributes.map(a => a.serialize()),
      allergens: this.allergens.map(a => a.id),
      availableDays: this.availableDays,
      productNumber: this.productNumber
    };
  }
}

export class Article extends ArticleDTO {
}
