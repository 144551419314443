import {Injectable} from '@angular/core';
import {Order, OrderDTO} from '../models/order';
import {DataService} from './data.service';
import {map, share, tap} from 'rxjs/operators';
import {ToastController} from '@ionic/angular';
import {OrderGroup} from '../models/order-group';
import {OrderArticle} from '../models/article/order-article';
import {UserService} from './user.service';
import {User} from '../models/user';
import {LoadingService} from './loading.service';
import {ToastService} from './toast.service';
import {DeliveryType} from '../enums/delivery-type';
import {CheckoutResponse} from '../models/checkout-response';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  public activeColor: string;
  public listView = false;
  public selectedOrder: Order;
  public lastRefresh: Date;

  public limit = 50;
  public offset = 0;
  public type: string;
  public branchId: number;
  public orders: Order[];

  public allSelected = false;
  public indeterminateState = false;
  public noMoreOrders = false;
  public orderProperty: string;
  public orderDirection: string;
  public keyboardIsVisible = false;
  public generatePdf = false;
  public archive = false;
  public grouped = false;
  public tableOrders = false;

  public deliveryTypes = DeliveryType;
  public deliveryType = this.deliveryTypes.ALL;
  public deliveryTypeLabels: { [key in DeliveryType]: string } = {
    [DeliveryType.ALL]: 'Alle anzeigen',
    [DeliveryType.TAKEAWAY]: 'Abholung',
    [DeliveryType.DELIVERY]: 'Lieferung',
  };

  public order: Order;

  constructor(
    private dataService: DataService,
    private loadingService: LoadingService,
    public toastController: ToastController,
    public userService: UserService,
    private toastService: ToastService
  ) {
  }

  get selectedOrders() {
    return this.orders?.filter(order => order.checked);
  }

  get checkedBranchId() {
    return this?.selectedOrders[0]?.branch ?? null;
  }

  setListView(value: boolean) {
    this.listView = value;
    localStorage.setItem('listView', JSON.stringify(this.listView));
  }

  setBranchId(value: number) {
    this.branchId = value;
    localStorage.setItem('branch', JSON.stringify(this.branchId));
  }

  setDeliveryType(value: DeliveryType) {
    this.deliveryType = value;
    localStorage.setItem('deliveryType', JSON.stringify(this.deliveryType));
  }

  async getOrder(id: number): Promise<Order> {
    await this.loadingService.present();

    return this.dataService.get<Order>('/order/' + id, true)
      .pipe(map(response => {
        this.loadingService.dismiss();
        return new Order().deserialize(response) as Order;
      }))
      .toPromise()
      .catch(_ => {
        this.loadingService.dismiss();
        this.toastService.presentToast('Laden fehlgeschlagen', 'danger');
        return null;
      });
  }

  public async getOrderBon(id): Promise<string> {
    return this.dataService.get<string>('/order/' + id + '/print', true)
      .pipe(map(response => response))
      .toPromise()
      .catch(_ => {
        this.toastService.presentToast('Laden fehlgeschlagen', 'danger');
        return null;
      });
  }

  public buildParams() {
    this.setOrderProperties();
    return {
      ...(this.limit ? {limit: this.limit} : {}),
      ...(this.offset ? {offset: this.offset} : {}),
      ...(this.branchId ? {branch: this.branchId} : {}),
      ...(this.orderProperty ? {orderProperty: this.orderProperty} : {}),
      ...(this.orderDirection ? {orderDirection: this.orderDirection} : {}),
      ...(this.archive ? {archive: this.archive} : {}),
      ...(this.tableOrders ? {table: this.tableOrders} : {}),
      ...(this.deliveryType ? {deliveryType: this.deliveryType} : {}),
      type: this.type,
    };
  }

  public async getOrders(force = false): Promise<Order[] | undefined> {
    this.offset = 0;

    await this.loadingService.present();

    if (this.orders?.length === 0 || force) {
      return this.dataService.getMultiple('/order', Order, true, true, this.buildParams())
        .pipe(tap(async data => {
          this.lastRefresh = new Date();
          this.orders = data;
          this.noMoreOrders = this.orders.length < this.limit;

          await this.loadingService.dismiss();

          return this.orders;
        }))
        .pipe(share())
        .toPromise()
        .catch(_ => {
          this.loadingService.dismiss();
          this.toastService.presentToast('Laden fehlgeschlagen', 'danger');
          return null;
        });
    } else {
      await this.loadingService.dismiss();

      return this.orders;
    }
  }

  async loadMore(event) {
    this.offset = this.orders.length;

    const newOrders = await this.appendOrders(true);
    this.orders = this.orders.concat(newOrders);
    event.target.complete();
  }

  public async appendOrders(force = false): Promise<Order[] | undefined> {
    if (this.orders.length === 0 || force) {
      return this.dataService.getMultiple('/order', Order, true, true, this.buildParams())
        .pipe(tap(data => {
          if (data.length < this.limit) {
            this.noMoreOrders = true;
          }
          return data;
        }))
        .pipe(share())
        .toPromise()
        .catch(_ => {
          this.toastService.presentToast('Laden fehlgeschlagen', 'danger');
          return null;
        });
    } else {
      return this.orders;
    }
  }

  buildOrderGroupParams() {
    this.setOrderProperties();
    return {
      ...(this.limit ? {limit: this.limit} : {}),
      ...(this.offset ? {offset: this.offset} : {}),
      ...(this.branchId ? {branch: this.branchId} : {}),
      ...(this.orderProperty ? {orderProperty: this.orderProperty} : {}),
      ...(this.orderDirection ? {orderDirection: this.orderDirection} : {}),
    };
  }

  async updateOrder(existingOrder: Order, newData: any) {
    await this.loadingService.present();

    const updatedOrder = new Order().deserialize(newData);

    return this.dataService.put<OrderDTO>('/order' + (this.tableOrders ? '/table' : '') + '/' + existingOrder.id, true, false, updatedOrder.serialize())
      .pipe(map(data => {
        this.loadingService.dismiss();
        this.toastService.presentToast('Aktualisieren erfolgreich', 'success');
      }))
      .toPromise()
      .catch(_ => {
        this.loadingService.dismiss();
        this.toastService.presentToast('Aktualisieren fehlgeschlagen', 'danger');
        return null;
      });
  }

  async postOrder(body: any) {
    await this.loadingService.present();

    return this.dataService.post('/order', body, null, true)
      .pipe(map(data => {
        this.loadingService.dismiss();
        this.toastService.presentToast('Bestellung erstellt', 'success');
      }))
      .toPromise()
      .catch(_ => {
        this.loadingService.dismiss();
        this.toastService.presentToast('Erstellen fehlgeschlagen', 'danger');
        return null;
      });
  }

  async postTableOrder(articles: OrderArticle[]): Promise<boolean> {
    const user = await this.userService.getMe() as User;

    const order = {
      notificationToken: '',
      articles: articles.map(article => article.serialize()),
      table: user.table.id
    };

    await this.loadingService.present();

    return this.dataService.post('/order/table', order, null, true).pipe(
      map(() => {
        this.loadingService.dismiss();
        this.toastService.presentToast(
          'Bestellung aufgegeben',
          'success',
          'Ihre Bestellung wurde aufgegeben. Sie wird nun bearbeitet.'
        );
        return true;
      }),
      share()
    ).toPromise()
      .catch(_ => {
        this.loadingService.dismiss();
        this.toastService.presentToast('Bestellung konnte nicht aufgegeben werden',
          'danger',
          'Versuchen Sie es erneut oder kontaktieren Sie unser Personal'
        );
        return null;
      });
  }

  async submitOrders(orders: Order[]) {
    await this.loadingService.present();

    return this.dataService.put<OrderDTO[]>('/order/submit', true, false, orders.map(order => order.id))
      .pipe(map(data => {
        this.loadingService.dismiss();
        this.toastService.presentToast('Bestellungen erfolgreich bestätigt', 'success');
      }))
      .toPromise()
      .catch(_ => {
        this.loadingService.dismiss();
        this.toastService.presentToast('Aktualisieren fehlgeschlagen', 'danger');
        return null;
      });
  }

  async declineOrders(orders: Order[]) {
    await this.loadingService.present();

    return this.dataService.put<OrderDTO[]>('/order/decline', true, false, orders.map(order => order.id))
      .pipe(map(data => {
        this.loadingService.dismiss();
        this.toastService.presentToast('Bestellungen erfolgreich abgelehnt', 'success');
      }))
      .toPromise()
      .catch(_ => {
        this.loadingService.dismiss();
        this.toastService.presentToast('Aktualisieren fehlgeschlagen', 'danger');
        return null;
      });
  }

  allChecked() {
    return this.orders.length === this.selectedOrders.length;
  }

  someChecked() {
    return this.selectedOrders.length > 0 && this.selectedOrders.length !== this.orders.length;
  }

  selectOrder(order: Order) {
    if (this.checkedBranchId === null || order.branch === this.checkedBranchId) {
      if (!order.orderGroup) {
        order.checked = !order.checked;
      }

      this.allSelected = this.allChecked();
      this.indeterminateState = this.someChecked();
    }
  }

  selectAllOrders() {
    const nextValue = !this.allChecked();
    this.allSelected = this.allChecked();
    this.indeterminateState = this.someChecked();

    this.orders = this.orders.map(order => new Order().deserialize({...order, checked: nextValue}) as Order);
  }

  async groupOrders(orders: Order[]) {
    await this.loadingService.present();

    return this.dataService.post<OrderDTO[]>('/order-group', orders.map(order => order.id))
      .pipe(map(data => {
        this.loadingService.dismiss();
        this.toastService.presentToast('Bestellungen erfolgreich gruppiert', 'success');
        return new OrderGroup().deserialize(data);
      }))
      .toPromise()
      .catch(_ => {
        this.loadingService.dismiss();
        this.toastService.presentToast('Gruppieren fehlgeschlagen', 'danger');
        return null;
      });
  }

  resetSelectedOrders() {
    this.indeterminateState = false;
    this.allSelected = false;
    this.orders = this.orders?.map(order => new Order().deserialize({...order, checked: false}) as Order);
  }

  setTableOrders() {
    localStorage.setItem('tableOrders', JSON.stringify(this.tableOrders));
  }

  async checkout(checkoutObj: any) {
    await this.loadingService.present();

    return this.dataService.post('/order/checkout', checkoutObj)
      .pipe(map(data => {
        this.loadingService.dismiss();

        return new CheckoutResponse().deserialize(data);
      }))
      .toPromise()
      .catch(_ => {
        this.loadingService.dismiss();
        this.toastService.presentToast(
          'Ungültige Artikel',
          'danger',
          'Leider sind nicht alle Artikel zur gleichen Zeit verfügbar'
        );
        return null;
      });
  }

  async tableCheckout(cart: OrderArticle[]) {
    await this.loadingService.present();

    return this.dataService.post<OrderDTO[]>('/order/table/checkout', cart.map(article => article.serialize()))
      .pipe(map(data => {
        this.loadingService.dismiss();

        return new Order().deserialize(data).serialize();
      }))
      .toPromise()
      .catch(_ => {
        this.loadingService.dismiss();
        this.toastService.presentToast(
          'Ungültiger Warenkorb',
          'danger',
          'Versuchen Sie es erneut oder kontaktieren Sie unser Personal'
        );
        return null;
      });
  }

  private setOrderProperties() {
    switch (this.type) {
      case 'OPEN':
        this.orderProperty = 'tstamp';
        this.orderDirection = 'DESC';
        break;
      case 'SUBMITTED':
        if (!this.tableOrders) {
          this.orderProperty = 'desiredPickupTime';
          if (this.archive) {
            this.orderDirection = 'DESC';
          } else {
            this.orderDirection = 'ASC';
          }
        } else {
          this.orderProperty = 'tstamp';
          this.orderDirection = 'DESC';
        }
        break;
      default:
        this.orderProperty = 'timeStamp';
        this.orderDirection = 'DESC';
        break;
    }
  }
}
