import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {OrderService} from '../../services/order.service';

@Component({
  selector: 'app-article-note',
  templateUrl: './article-note.component.html',
  styleUrls: ['./article-note.component.scss'],
})
export class ArticleNoteComponent implements OnInit {
  //@Input() inputNote: string;
  @Input() public comment: string;

  constructor(
    public modalController: ModalController,
    public orderService: OrderService
  ) { }

  async submit(){
     await this.modalController.dismiss({comment: this.comment});

  }
  ngOnInit() {

  }

  onBlur() {
    this.orderService.keyboardIsVisible = false;
  }

  onFocus() {
    this.orderService.keyboardIsVisible = true;
  }
}
