import {Component, OnInit} from '@angular/core';
import {NavController, Platform} from '@ionic/angular';
import {Router} from '@angular/router';
import {AuthService} from './services/auth.service';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import {Capacitor} from '@capacitor/core';
import {PrintService} from './services/print.service';
import {OrderService} from './services/order.service';
import {CartService} from './services/cart.service';
import {OrderArticle} from './models/article/order-article';
import {ClientService} from './services/client.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  ready = false;

  constructor(
    private authService: AuthService,
    private print: PrintService,
    private platform: Platform,
    private navController: NavController,
    private router: Router,
    private screenOrientation: ScreenOrientation,
    private printService: PrintService,
    private orderService: OrderService,
    private cartService: CartService,
    private clientService: ClientService
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this.initApp();
  }

  private async initApp() {
    await this.platform.ready();
    if (Capacitor.getPlatform() !== 'web') {
      await this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);
    }

    this.initPrint();
    this.orderService.generatePdf = localStorage.getItem('generatePdf') === 'true';

    if (await this.authService.isLoggedIn()) {
      await this.clientService.getClient();
      if (!window.location.pathname.includes('dashboard')) {
        await this.navController.navigateRoot('dashboard');
      }
    } else {
      await this.navController.navigateRoot('login');
    }

    if (localStorage.getItem('listView')) {
      this.orderService.setListView(JSON.parse(localStorage.getItem('listView')));
    }
    if (localStorage.getItem('tableOrders')) {
      this.orderService.tableOrders = JSON.parse(localStorage.getItem('tableOrders'));
    }
    if (localStorage.getItem('deliveryType')) {
      this.orderService.deliveryType = JSON.parse(localStorage.getItem('deliveryType'));
    }

    if (localStorage.getItem('branch')) {
      this.orderService.setBranchId(JSON.parse(localStorage.getItem('branch')));
    } else {
      this.orderService.setBranchId(null);
    }

    if (localStorage.getItem('cart')) {
      const cart = JSON.parse(localStorage.getItem('cart'));
      for (const item of cart) {
        this.cartService.cart.push(new OrderArticle().deserialize(item) as OrderArticle);
      }
    }

    this.ready = true;
  }

  private initPrint() {
    if (localStorage.getItem('printer')) {
      this.print.selectDevice(
        JSON.parse(localStorage.getItem('printer')),
        JSON.parse(localStorage.getItem('printing'))
      );
    } else {
      this.print.unselectDevice();
    }

    this.printService.checkBluetoothEnabled()
      .then(() => {
        this.printService.bluetoothIsEnabled = true;
      })
      .catch(() => {
        this.printService.bluetoothIsEnabled = false;
      });
  }
}
