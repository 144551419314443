import {Serializable} from './serializable.abstract';
import {OrderArticle} from './article/order-article';
import {Table} from './table';

export class OrderDTO extends Serializable {
  id: number;
  internalId: string;
  pid: number;
  branch: number;
  tstamp: string;
  firstName: string;
  lastName: string;
  street: string;
  zip: string;
  location: string;
  email: string;
  phone: string;
  netSum: number;
  grossSum: number;
  tax: number;
  desiredPickupTime: string;
  sellerNotice: string;
  orderGroup: number;
  table: Table;
  delivery: boolean;
  deliveryFee: number;
  deliveryInstructions: string;
  paymentType: string;
  articles: Array<OrderArticle> = [];

  public override deserialize(input: any): Serializable {
    super.deserialize(input);
    this.deserializeComplexArray('articles', input, OrderArticle);
    this.deserializeDate('tstamp', input);
    this.deserializeDate('desiredPickupTime', input);
    return this;
  }
}

export class Order extends OrderDTO{
  checked: boolean;
}
