import {Injectable} from '@angular/core';
import {DataService} from './data.service';
import {map, share} from 'rxjs/operators';
import {LoadingService} from './loading.service';
import {Client} from '../models/client';
import {ToastService} from './toast.service';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  public client: Client;

  constructor(
    private dataService: DataService,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private formBuilder: FormBuilder
  ) {
  }

  async getClient(force = false): Promise<Client> {
    await this.loadingService.present();

    const clientId = localStorage.getItem('clientId');

    if (!this.client || force) {
      return this.dataService.get<Client>('/client/' + clientId, true)
        .pipe(map(response => {
          this.loadingService.dismiss();
          this.client = new Client().deserialize(response) as Client;
          return this.client;
        }))
        .toPromise()
        .catch(_ => {
          this.loadingService.dismiss();
          this.toastService.presentToast('Laden fehlgeschlagen', 'danger');
          return null;
        });
    } else {
      return this.client;
    }
  }

  async updateClient(client): Promise<boolean> {
    await this.loadingService.present();

    return this.dataService.put('/client/' + client.internalId, true, true, client)
      .pipe(
        map(() => {
          this.loadingService.dismiss();
          this.toastService.presentToast('Aktualisieren erfolgreich', 'success');
          return true;
        }),
        share())
      .toPromise()
      .catch(_ => {
        this.loadingService.dismiss();
        this.toastService.presentToast('Aktualisieren fehlgeschlagen', 'danger');
        return null;
      });
  }

  getForm() {
    return this.formBuilder.group({
      id: new FormControl(null),
      internalId: new FormControl(null),
      additionalInformation: new FormControl(''),
      additionalInformationLunchMenu: new FormControl(''),
      availabilityTitle: new FormControl(''),
      availabilityDescription: new FormControl(''),
      salutationMorning: new FormControl(''),
      salutationMidday: new FormControl(''),
      salutationAfternoon: new FormControl(''),
      salutationEvening: new FormControl(''),
      availability: new FormControl(''),
      deliveryActive: new FormControl(''),
      deliveryInterval: new FormControl(''),
      deliveryNotificationTime: new FormControl(''),
      deliveryNotificationTitle: new FormControl(''),
      deliveryNotificationText: new FormControl('')
    });
  }

  patchForm(clientForm: FormGroup, client: Client) {
    clientForm.patchValue(client);
    clientForm.get('availabilityTitle').setValue(this.client.availability.title);
    clientForm.get('availabilityDescription').setValue(this.client.availability.description);
    clientForm.get('salutationMorning').setValue(this.client.textElements.salutations.morning);
    clientForm.get('salutationMidday').setValue(this.client.textElements.salutations.midday);
    clientForm.get('salutationAfternoon').setValue(this.client.textElements.salutations.afternoon);
    clientForm.get('salutationEvening').setValue(this.client.textElements.salutations.evening);
    clientForm.get('availability').setValue(this.client.availability.status);
    clientForm.get('deliveryActive').setValue(this.client.delivery.active);
    clientForm.get('deliveryInterval').setValue(this.client.delivery.interval);
    clientForm.get('deliveryNotificationTime').setValue(this.client.delivery.notificationTime);
    clientForm.get('deliveryNotificationTitle').setValue(this.client.delivery.notificationTitle);
    clientForm.get('deliveryNotificationText').setValue(this.client.delivery.notificationText);

    return clientForm;
  }
}
