import {Serializable} from './serializable.abstract';

export class MenuCategoryDTO extends Serializable {
  id: number;
  title = '';
  titleIntern = '';
  selectionRequired: boolean;

  deserialize(input: any): Serializable {
    super.deserialize(input);
    return this;
  }

  serialize(): any {
    return {
      id: this.id,
      title: this.title,
      titleIntern: this.titleIntern,
      selectionRequired: this.selectionRequired
    };
  }
}

export class MenuCategory extends MenuCategoryDTO {
}
