import {Injectable} from '@angular/core';
import {OrderArticle} from '../models/article/order-article';
import {ArticleService} from './article.service';
import {Article} from '../models/article/article';
import {ArticleNoteComponent} from '../components/article-note/article-note.component';
import {AlertController, ModalController, ToastController} from '@ionic/angular';
import {SelectedArticleService} from './selected-article.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public cart: OrderArticle[] = [];

  constructor(public articleService: ArticleService,
              public modalController: ModalController,
              public alertController: AlertController,
              public selectedArticleService: SelectedArticleService,
              public toastController: ToastController
  ) {
  }

  async addSelectedToCart() {
    if (this.selectedArticleService.selectedArticles.length === 0) {
      return;
    }

    for (const articleId of this.selectedArticleService.selectedArticles) {
      const orderArticle = this.getArticle(articleId);
      const article = await this.articleService.getLoadedArticle(articleId);

      if (orderArticle) {
        let amount = orderArticle.count + article.clickAmount;
        if (article.clickAmount === 0) {
          amount++;
        }
        if (amount > article.minOrderAmount) {
          amount = article.minOrderAmount;
        }

        this.addToCart(orderArticle.title, amount, orderArticle.id, orderArticle.comment);
      } else {
        let amount = article.clickAmount;
        if (article.clickAmount === 0) {
          amount++;
        }
        this.addToCart(article.title, amount, article.id, '');
      }
    }
    let message = this.selectedArticleService.selectedArticles.length + ' ';
    if (this.selectedArticleService.selectedArticles.length === 1) {
      message += 'Produkt wurde zu Ihrem Menü hinzugefügt!';
    } else {
      message += 'Produkte wurden zu Ihrem Menü hinzugefügt!';
    }
    await this.createToast(message, 2000);
    this.selectedArticleService.reset();
  }

  addToCart(title: string, count: number, id: number, comment: string) {
    const existingArticle = this.getArticle(id);

    this.articleService.getUnits().then(units => {
      this.articleService.getSellUnits().then(sellUnits => {
        if (existingArticle !== undefined) {
          const index = this.cart.indexOf(existingArticle);
          this.cart.splice(index, 1);
        }
        const order = {
          title,
          count,
          id,
          unit: units.find(unit => unit.unit === 'PIECE'),
          sellUnit: sellUnits.find(sellUnit => sellUnit.label === 'Stück'),
          comment
        };
        this.cart.push(new OrderArticle().deserialize(order) as OrderArticle);
        localStorage.setItem('cart', JSON.stringify(this.cart));
      });
    });
  }

  setCount(count: number, article: Article) {
    if (article.clickAmount >= 1) {
      count = Number((count / article.clickAmount).toFixed(0)) * article.clickAmount;
    }
    this.getArticle(article.id).count = count;
    const orderArticle = this.getArticle(article.id);
    orderArticle.count = this.checkCount(article, orderArticle.count);
  }

  checkCount(article: Article, count: number): number {
    if (count <= article.minOrderAmount) {
      if (article.minOrderAmount > 0) {
        count = article.minOrderAmount;
      } else {
        count = 1;
      }
    }
    if (count > article.maxOrderAmount) {
      count = article.maxOrderAmount;
    }

    return count;
  }

  changeCount(article: Article, up = true): number {
    const orderArticle = this.getArticle(article.id);
    if (article.clickAmount < 1) {
      if (up) {
        orderArticle.count++;
      } else {
        orderArticle.count--;
      }
    } else {
      if (up) {
        if (orderArticle.count < article.clickAmount) {
          orderArticle.count = article.clickAmount;
        } else {
          orderArticle.count += article.clickAmount;
        }
      } else {
        orderArticle.count -= article.clickAmount;
      }
    }
    orderArticle.count = this.checkCount(article, orderArticle.count);
    return orderArticle.count;
  }

  calcArticlePrice(orderArticle: OrderArticle): number {
    const article = this.getFullArticle(orderArticle.id);
    if (article) {
      return (article.price * orderArticle.count) / article.unit.amount;
    }
    return 0;
  }

  removeFromCart(id: number) {
    const article = this.getArticle(id);
    const index = this.cart.indexOf(article);
    this.cart.splice(index, 1);
    localStorage.setItem('cart', JSON.stringify(this.cart));
  }

  getFullArticle(id: number): Article {
    let selectedArticle;
    this.articleService.articles?.forEach((article) => {
      if (article.id === id) {
        selectedArticle = article;
      }
    });
    return selectedArticle;
  }

  getArticle(id: number): OrderArticle {
    let existingArticle;
    this.cart.forEach(article => {
      if (article.id === id) {
        existingArticle = article;
      }
    });
    return existingArticle;
  }

  async editComment(id: number) {
    const orderArticle = this.getArticle(id);
    const modal = await this.modalController.create({
      component: ArticleNoteComponent,
      cssClass: 'modal--big',
      componentProps: {comment: orderArticle.comment}
    });

    await modal.present();
    await modal.onDidDismiss().then((modelData) => {
      if (modelData.data !== undefined) {
        orderArticle.comment = modelData.data.comment;
        this.removeFromCart(orderArticle.id);
        this.cart.push(orderArticle);
        localStorage.setItem('cart', JSON.stringify(this.cart));
      }
    });
  }

  async createToast(message: string, duration: number) {
    const toast = await this.toastController.create({
      message,
      duration,
      cssClass: 'addedToMenuCustomToast'
    });

    await toast.present();
  }

  countArticles(): number {
    return this.cart.length;
  }
}
