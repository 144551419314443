import {Serializable} from './serializable.abstract';

export class CategoryDTO extends Serializable {
  id: number;
  title: string;

  public override deserialize(input: any): Serializable {
    super.deserialize(input);
    return this;
  }
}

export class Category extends CategoryDTO{
}
