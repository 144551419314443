import {Serializable} from './serializable.abstract';

export class BranchDTO extends Serializable {
  id: number;
  title: string;

  public override deserialize(input: any): Serializable {
    super.deserialize(input);
    return this;
  }
}

export class Branch extends BranchDTO{
}
