import {Serializable} from './serializable.abstract';
import {Order} from './order';

export class OrderGroupDTO extends Serializable {
  id: number;
  internalId: string;
  tstamp: number;
  orders: Array<Order> = [];

  public override deserialize(input: any): Serializable {
    super.deserialize(input);
    this.deserializeComplexArray('orders', input, Order);
    return this;
  }
}

export class OrderGroup extends OrderGroupDTO {
}
