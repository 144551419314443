import {BaseArticle} from './base-article';
import {Serializable} from '../serializable.abstract';
import {MenuArticle} from '../menu-article';

export class CheckoutArticleDTO extends BaseArticle {
  amount: number;
  clickAmount: number;
  comment: string;
  type: number;
  images: number[];
  maxOrderAmount: number;
  minItems: number;
  additionalArticles: MenuArticle[] = [];

  public deserialize(input: any): Serializable {
    super.deserialize(input);
    this.deserializeComplexArray('additionalArticles', input, MenuArticle);
    return this;
  }
}

export class CheckoutArticle extends CheckoutArticleDTO {
}
