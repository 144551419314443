export const environment = {
  production: false,
  apiUrl: 'https://siwaorderpim.dev-vm.siwa.vpn/api',
  apiUrlPilot: 'https://siwaorderpim.pilot01.siwa.at/api',
  apiUrlProd: 'https://pim.orderfant.app/api',
  debug: false,
  firebase: {
    apiKey: 'AIzaSyCgWYziyX6kWvGzUnNPzfuIUtmUNJzPoJE',
    authDomain: 'orderfant-verkauf.firebaseapp.com',
    projectId: 'orderfant-verkauf',
    storageBucket: 'orderfant-verkauf.appspot.com',
    messagingSenderId: '799067437512',
    appId: '1:799067437512:web:d719544532392ae87ca8fd',
    vapidKey: 'BBNdojZe_Vx-X_hfSmpBFQqv2RDFG0SP2k8sRN0byrgV8HcJTPz5_vRl7lqE_LKLj2aDW_xP3eeece7C3Tn781Y'
  }
};
