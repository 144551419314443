import {Injectable} from '@angular/core';
import {DataService} from './data.service';
import {map, share} from 'rxjs/operators';
import {User} from '../models/user';
import {UserService} from './user.service';
import {NavController} from '@ionic/angular';
import {OrderService} from './order.service';
import {NotificationService} from './notification.service';
import {LoadingService} from './loading.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedInUser: User;
  private clientId: string;

  constructor(
    public dataService: DataService,
    public orderService: OrderService,
    public userService: UserService,
    private navCtrl: NavController,
    private loadingService: LoadingService,
    private notificationService: NotificationService
  ) {
  }

  async login(clientId: string, username: string, password: string): Promise<boolean> {
    this.clientId = clientId;
    localStorage.setItem('clientId', clientId);

    return this.dataService.post('/auth/login', {clientId, username, password}, null, false)
      .pipe(
        map(async (data: { token: string }) => {
          localStorage.setItem('token', data.token);
          this.notificationService.requestPermission();
          this.loggedInUser = await this.userService.getMe();
          return true;
        }),
        share()
      )
      .toPromise();
  }

  async logout() {
    await this.loadingService.present();

    this.resetStorage();
    await this.navCtrl.navigateRoot('login');
    await this.loadingService.dismiss();
  }

  async isLoggedIn() {
    try {
      this.notificationService.requestPermission();
      this.loggedInUser = await this.userService.getMe();
      return true;
    } catch (e) {
      console.log(e);
    }
    return false;
  }

  private resetStorage() {
    localStorage.removeItem('clientId');
    localStorage.removeItem('token');
    localStorage.removeItem('listView');
    localStorage.removeItem('branch');
    this.orderService.orders = undefined;
    this.orderService.offset = 0;
  }
}
