import {Injectable} from '@angular/core';
import {User} from '../models/user';
import {DataService} from './data.service';
import {map} from 'rxjs/operators';
import {ToastService} from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    public dataService: DataService,
    private toastService: ToastService
  ) {
  }

  getMe(): Promise<User> {
    return this.dataService.get<User>('/me', true)
      .pipe(map(response => new User().deserialize(response) as User))
      .toPromise();
  }
}
