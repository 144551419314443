import {Injectable} from '@angular/core';
import {LoadingController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loading: HTMLIonLoadingElement;
  private isLoading = false;

  constructor(
    private loadingCtrl: LoadingController,
  ) {
  }

  public async present() {

    if (!this.isLoading && !this.loading) {
      this.isLoading = true;

      this.loading = await this.loadingCtrl.create({spinner: 'crescent', mode: 'ios'});
      await this.loading.present();
    }
  }

  public async dismiss() {

    if (this.isLoading && this.loading) {
      this.isLoading = false;

      await this.loading.dismiss();
      this.loading = null;
    }
  }
}
