import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {RequestInterceptor} from './interceptors/request-interceptor';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import {DatePipe, registerLocaleData} from '@angular/common';
import localeDEAT from '@angular/common/locales/de-AT';
import {environment} from '../environments/environment';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireMessagingModule} from '@angular/fire/compat/messaging';
import {BluetoothSerial} from '@ionic-native/bluetooth-serial/ngx';
import {QuillModule} from 'ngx-quill';
import {FormBuilder} from '@angular/forms';

registerLocaleData(localeDEAT);

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    QuillModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule
  ],
  providers: [
    BluetoothSerial,
    FormBuilder,
    ScreenOrientation,
    DatePipe,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-AT'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
