import {Serializable} from './serializable.abstract';

export class SellUnitDefinitionDTO extends Serializable {
  id: number;
  label: string;
  unit: string;
}

export class SellUnitDefinition extends SellUnitDefinitionDTO {
}
