import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SelectedArticleService {
  selectedArticles = [];
  checkboxes = [];
  wasReseted = 0;

  selectionChanged(id: number) {
    if (this.wasReseted > 0) {
      this.selectedArticles = [];
      this.wasReseted--;
      return;
    }
    let existing = false;
    this.selectedArticles.forEach(article => {
      if (article === id) {
        const index = this.selectedArticles.indexOf(article);
        this.selectedArticles.splice(index, 1);
        existing = true;
      }
    });
    if (!existing) {
      this.selectedArticles.push(id);
    }
  }

  reset() {
    const newArr = [];
    this.selectedArticles.forEach(box => {
      newArr[box] = false;
      this.wasReseted++;
    });
    this.checkboxes = newArr;
  }
}
