import {Injectable} from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/compat/messaging';
import {AlertController, ToastController} from '@ionic/angular';
import {Subject} from 'rxjs';
import {map, share} from 'rxjs/operators';
import {DataService} from './data.service';
import {NotificationType} from '../enums/notification-type';
import {OrderService} from './order.service';

import {ActionPerformed, PushNotifications, PushNotificationSchema, Token} from '@capacitor/push-notifications';
import {Capacitor} from '@capacitor/core';
import {PrintService} from './print.service';
import {ToastService} from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  alertTime = 2000;
  collectMenu = false;
  public onOrderChanged = new Subject();
  public onOrderGroupChanged = new Subject();
  public newOrderCount = 0;

  constructor(
    private afMessaging: AngularFireMessaging,
    private toastController: ToastController,
    private dataService: DataService,
    private orderService: OrderService,
    private printService: PrintService,
    private alertController: AlertController,
    private toastService: ToastService
  ) {
    const channel = new BroadcastChannel('sw-messages');
    channel.addEventListener('message', async msgPayload => {
      const notification = {
        title: msgPayload.data.notification.title,
        body: msgPayload.data.notification.body,
        data: msgPayload.data.data
      } as PushNotificationSchema;
      await this.handleMessage(notification);
    });
  }

  requestPermission() {
    if (Capacitor.getPlatform() === 'web') {
      // WEB
      this.afMessaging.requestPermission.subscribe((result) => {
        if (result === 'granted') {
          this.afMessaging.getToken
            .subscribe(
              (token) => {
                this.postNotificationToken(token);
                this.listen();
              },
              (error) => {
                console.error(JSON.stringify(error));
              },
            );
        }
      });

    } else {
      // ANDROID AND IOS
      PushNotifications.requestPermissions().then(async result => {
        if (result.receive === 'granted') {
          await PushNotifications.register();

          PushNotifications.addListener('registration',
            (token: Token) => {
              console.log('Notification-Token received');
              this.postNotificationToken(token.value);
              this.listen();
            }
          );

          PushNotifications.addListener('registrationError',
            (error: any) => {
              alert(JSON.stringify(error));
            }
          );
        }
      });
    }
  }

  async postNotificationToken(notificationToken: string): Promise<boolean> {
    return this.dataService.post('/admin/notification/register', {notificationToken}, null, true)
      .pipe(
        map(() => true),
        share()
      )
      .toPromise();
  }

  listen() {
    if (Capacitor.getPlatform() === 'web') {
      this.listenWeb();
    } else {
      this.listenMobile();
    }

  }

  async presentCollectMenuAlert() {
    const alert = await this.alertController.create({
      header: 'Ihre Bestellung ist fertig und abholbereit!',
      cssClass: 'collect-menu-alert',
      buttons: [
        {
          text: 'Verstanden',
          cssClass: 'collect-menu-alert-button',
          handler: () => {
            this.collectMenu = false;
          }
        }
      ],
      backdropDismiss: false
    });
    await alert.present();
  }

  public async handleMessage(msgPayload: PushNotificationSchema) {
    switch (msgPayload.data.type) {
      case NotificationType.ORDER_CREATED:
        this.onOrderChanged.next();
        try {
          this.newOrderCount++;

          await this.toastService.presentToast('Eine neue Bestellung ist eingetroffen!', 'charcoal');

          await this.printService.checkBluetoothEnabled();

          if (this.printService.bluetoothIsEnabled && this.printService.selectedDevice && this.printService.printingIsEnabled) {
            const order = await this.orderService.getOrder(msgPayload.data.orderid);
            await this.printService.printOrder(order);
          }
        } catch (e) {
          console.log(e);
        }
        break;
      case NotificationType.ORDER_READY:
        this.onOrderChanged.next();
        this.collectMenu = true;
        //await this.presentCollectMenuToast("Bestellung fertig!", "Sie können ihr Menü jetzt abholen!");
        this.presentCollectMenuAlert();
        while (this.collectMenu) {
          const vib = window.navigator.vibrate(2000);
          await this.playNotificationSound();
          await new Promise(f => setTimeout(f, 500));
        }

        /*        await new HapticsWeb().impact();
                await new HapticsWeb().vibrate();
                await new HapticsWeb().notification();
                await Haptics.vibrate();
                await Haptics.notification();
        await Haptics.impact();*/

        break;
      case NotificationType.ORDERS_SUBMITTED:
        this.onOrderChanged.next();

        await this.toastService.presentToast(
          'Bestellung wurde bestätigt!'
        );
        break;
      case NotificationType.ORDERS_DECLINED:
        this.onOrderChanged.next();

        await this.toastService.presentToast(
          'Bestellung wurde abgelehnt!'
        );
        break;
      case NotificationType.ORDERGROUP_CREATED:
        this.onOrderGroupChanged.next();

        await this.toastService.presentToast(
          'Bestellgruppe wurde erstellt!'
        );
        break;
      case NotificationType.TOKEN_DELETE:
        break;
      default:
        break;
    }
  }

  async playNotificationSound() {
    const audio = new Audio();
    audio.src = '../../assets/audio/notification.mp3';
    audio.load();
    await audio.play();
  }

  private listenWeb() {
    this.afMessaging.messages
      .subscribe(async (msgPayload) => {
        const notification = {
          title: msgPayload.notification.title,
          body: msgPayload.notification.body,
          data: msgPayload.data
        } as PushNotificationSchema;
        await this.handleMessage(notification);
      });
  }

  private listenMobile() {
    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        await this.playNotificationSound();
        await this.handleMessage(notification);
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      async (actionPerformed: ActionPerformed) => {
        await this.handleMessage(actionPerformed.notification);
      }
    );
  }
}
