import {Serializable} from '../serializable.abstract';
import {UnitDefinition} from '../unit-definition';
import {SellUnitDefinition} from '../sell-unit-definition';

export class BaseArticleDTO extends Serializable {
  id: number;
  internalId: string;
  title: string;
  unit: UnitDefinition;
  sellUnit: SellUnitDefinition;

  public deserialize(input: any): Serializable {
    super.deserialize(input);
    this.deserializeComplexType('unit', input, UnitDefinition);
    this.deserializeComplexType('sellUnit', input, SellUnitDefinition);
    return this;
  }
}

export class BaseArticle extends BaseArticleDTO {
}
