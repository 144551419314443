import {Serializable} from './serializable.abstract';
import {CheckoutArticle} from './article/checkout-article';

export class CheckoutResponseDTO extends Serializable{
  availableDays: number[];
  disabledDays: number[];
  deliveryAvailableDays: number[];
  deliveryDisabledDays: number[];
  deliveryPossible: boolean;
  deliveryFee: number;
  leadTimeMax: number;
  paymentPossible: boolean;
  pickupMax: Date;
  pickupMin: Date;
  checkoutBasket: CheckoutArticle[];

  public deserialize(input: any): Serializable {
    super.deserialize(input);
    this.deserializeComplexArray('checkoutBasket', input, CheckoutArticle);
    this.deserializeDate('pickupMax', input);
    this.deserializeDate('pickupMin', input);
    return this;
  }
}

export class CheckoutResponse extends CheckoutResponseDTO {
}
