import {Injectable} from '@angular/core';
import {ToastButton, ToastController, ToastOptions} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  public toast: HTMLIonToastElement;

  constructor(
    private toastController: ToastController
  ) {
  }

  async dismissToast() {
    if (this.toast) {
      await this.toast.dismiss();
    }
  }

  async presentToast(title: string, color?: string, body?: string) {
    await this.dismissToast();

    this.toast = await this.toastController.create({
      header: title,
      message: body,
      color: color ?? 'charcoal',
      duration: 4000,
      cssClass: 'toast-text--light'
    });
    await this.toast.present();
  }

  async presentButtonToast(
    title: string,
    color: string = 'charcoal',
    body?: string,
    btnText?: string,
    duration: number = 0,
    callback?: () => void
  ) {
    await this.dismissToast();

    const toastOptions: ToastOptions = {
      header: title,
      message: body,
      color,
      cssClass: 'toast-text--light',
      duration,
      buttons: []
    };

    if (btnText) {
      const button: ToastButton = {
        text: btnText,
        role: 'cancel'
      };

      if (callback) {
        button.handler = () => {
          callback();
          this.dismissToast();
        };
      } else {
        button.handler = () => {
          this.dismissToast();
        };
      }

      toastOptions.buttons.push(button);
    }

    this.toast = await this.toastController.create(toastOptions);
    await this.toast.present();
  }

}
