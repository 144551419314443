import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {from, Observable, throwError} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {DataService} from '../services/data.service';
import {NavController} from '@ionic/angular';
import {environment} from '../../environments/environment';

export const INTERCEPTOR_SKIP_LOADING_HEADER = 'X-Skip-Loading-Interceptor';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(
    public authService: AuthService,
    private dataService: DataService,
    private navController: NavController
  ) {
  }

  private static async updateHeader(req: HttpRequest<any>): Promise<HttpRequest<any>> {
    const token = localStorage.getItem('token');
    const clientId = localStorage.getItem('clientId');
    req = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${token}`)
    });

    if (clientId) {
      req = req.clone({
        params: req.params.set('client', clientId)
      });
    }
    return req;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.withCredentials) {
      return from(RequestInterceptor.updateHeader(request))
        .pipe(
          switchMap((updatedRequest: HttpRequest<any>) => this.handleRequest(updatedRequest, next))
        );
    } else {
      return this.handleRequest(request, next);
    }
  }

  handleRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = request.headers.delete(INTERCEPTOR_SKIP_LOADING_HEADER);
    request = request.clone({headers});
    request = this.addDebugParameter(request);
    return next.handle(request)
      .pipe(
        map(
          data => data
        ),
        catchError(
          e => {
            let errorMessage = '';
            switch (e.status) {
              case 403:
                this.authService.logout().then(
                  async () => {
                    await this.navController.navigateRoot('login');
                  }
                );
                break;
              case 404:
                errorMessage = 'Service aktuell nicht verfügbar.';
                break;
            }
            if (errorMessage === '') {
              errorMessage = e.error.message || e.statusText;
            }
            return throwError(errorMessage);
          }
        )
      );
  }

  private addDebugParameter(req: HttpRequest<any>) {
    if (!environment.debug) {
      return req;
    }

    let params = req.params;

    params = params.append('XDEBUG_SESSION_START', 'PHPSTORM');

    req = req.clone({
      params
    });

    return req;
  }
}
