import {Serializable} from './serializable.abstract';

export class ImageDTO extends Serializable {
  id: number;
  path: string;
  data: string | ArrayBuffer;

  public deserialize(input: any): Serializable {
    super.deserialize(input);
    return this;
  }
}

export class Image extends ImageDTO {
}
